  .login-div {
    width:430px;
    height: 700px;
    padding: 60px 35px 35px 35px;
    border-radius: 40px;
    background: #ecf0f3;
    box-shadow: 13px 13px 20px #cbced1,
                -13px -13px 20px #ffffff;
  }
  .logo {
    background:url("");
    width:100px;
    height: 100px;
    border-radius: 50%;
    margin:0 auto;
    box-shadow: 
    /* logo shadow */
    0px 0px 2px #5f5f5f,
    /* offset */
    0px 0px 0px 5px #ecf0f3,
    /*bottom right */
    8px 8px 15px #a7aaaf,
    /* top left */
    -8px -8px 15px #ffffff
    ;
  }
 
  .sub-title {
    text-align: center;
    font-size: 15px;
    padding-top: 7px;
    letter-spacing: 3px;
  }

  .mob-input {
    border: none;
    outline:none;
    background: none;
    font-size: 18px;
    color: #555;
    padding:20px 10px 20px 5px;
  }  
   
  .password {
    margin-bottom: 30px;
    border-radius: 25px;
    box-shadow: inset 8px 8px 8px #cbced1,
                inset -8px -8px 8px #ffffff;
  }

  .fields svg {
    height: 22px;
    margin:0 10px -3px 25px;
  }

  .signin-button {
    outline: none;
    border:none;
    cursor: pointer;
    width:100%;
    height: 60px;
    border-radius: 30px;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Lato', sans-serif;
    color:#fff;
    text-align: center;
    background: #EC1C24;
    box-shadow: 3px 3px 8px #b1b1b1,
                -3px -3px 8px #ffffff;
    transition: 0.5s;
  }
  .signin-button:hover {
    background:#7f1734;
  }
  .signin-button:active {
    background:#92000A;
  }
  .link {
    padding-top: 20px;
    text-align: center;
  }
  .link a {
    text-decoration: none;
    color:#aaa;
    font-size: 15px;
  }